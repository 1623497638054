
import { Modal, Box, Typography, List, ListItem, ListItemText, IconButton, Button, CardHeader, Card, CardMedia, CardContent, Link } from '@mui/material';
import type { Playlist } from '@spotify/web-api-ts-sdk';
import { SpotifyPlaylist } from "@ds-types/spotify";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
// import { maxWidth } from '@mui/system';

interface PlaylistModalProps {
   playlist: SpotifyPlaylist | boolean;
   spotifyauth: string;
}
const isSpotifyPlaylist = (value: any): value is SpotifyPlaylist => {
   return (
      value !== null &&
      typeof value === 'object' &&
      'id' in value &&
      'snapshot_id' in value &&
      'name' in value &&
      'uri' in value
   );
}
const getCreatedPlaylist = async (playlist: SpotifyPlaylist, spotifyauth: string) => {
   const response = await fetch(`https://api.spotify.com/v1/playlists/${playlist.id}`, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${spotifyauth}`,
      },
   });
   const data = await response.json() as Playlist;
   return data;
};



const SpotifyModal: React.FC<PlaylistModalProps> = ({ playlist, spotifyauth }) => {
   const [modalOpen, setModalOpen] = React.useState(true);
   const handleOpen = () => setModalOpen(true);
   const handleClose = () => setModalOpen(false);

   const [createdPlaylist, setCreatedPlaylist] = React.useState<SpotifyPlaylist | null>(null);

   useEffect(() => {
      const fetchPlaylist = async () => {
         if (isSpotifyPlaylist(playlist)) {
            try {
               const playlistDetails = await getCreatedPlaylist(playlist, spotifyauth);
               console.log(`🥮grim__playlistDetails==> ==> ==>playlistDetails`);
               console.log({ grim__playlistDetails: playlistDetails });
               if (playlistDetails) {
                  setCreatedPlaylist(playlistDetails);
               }
            } catch (error) {
               console.error('Error fetching playlist:', error);
            }
         }
      };

      fetchPlaylist();
   }, [playlist, spotifyauth]);

   if (isSpotifyPlaylist(playlist)) {
      return (
         <>
            <Button onClick={handleOpen}
               variant="contained"
               size="large"
            >View Playlist Details</Button>

            <Modal
               open={modalOpen}
               onClose={handleClose}
               aria-labelledby="playlist-modal-title"
            >
               <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 800,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  color: 'black',
                  p: 4,
                  borderRadius: 2,
                  maxHeight: '80vh',
                  overflow: 'auto'
               }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" mb={2}>
                     <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                     </IconButton>
                     <Typography id="playlist-modal-title" variant="h6" component="h2">
                        Get that Playlist
                     </Typography>
                     {createdPlaylist && (
                        <Card sx={{ maxWidth: 369 }}>
                           <CardHeader>
                              {createdPlaylist.name} - {createdPlaylist.owner.display_name}
                           </CardHeader>
                           <CardMedia
                              component="img"
                              height={createdPlaylist.images[0].height}
                              width={createdPlaylist.images[0].width}
                              image={createdPlaylist.images[0].url}
                              style={{ aspectRatio: 1 / 1, height: 'auto' }}
                           />
                           <CardContent>
                              <Typography variant="body2" color="text.secondary">
                                 {createdPlaylist.description}
                              </Typography>
                              <Link
                                 href={createdPlaylist.external_urls.spotify}
                                 target="_blank"
                                 rel="noopener noreferrer">View Your Playlist</Link>
                           </CardContent>
                        </Card>
                     )}
                  </Box>
                  {/* 
            <List>
               {playlist.tracks.map((track, index) => (
                  <ListItem key={index}>
                     <ListItemText
                        primary={track.title}
                        secondary={track.artist}
                     />
                  </ListItem>
               ))}
            </List> */}
               </Box>
            </Modal>
         </>
      );
   }

};

export default SpotifyModal;