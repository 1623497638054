import * as React from "react";
import { generateContent } from "@ds-services/nlp/getwords";

// <=== 💸  MUI Stuff  💸 ===>
import DotaSpottoTheme from "@ds-components/General/dotaspottotheme";
// import DotaSpottoTheme from "./dotaspottotheme";
import { Button, createTheme, ThemeProvider, Backdrop, CircularProgress, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridRowSelectionModel,
} from "@mui/x-data-grid";
import type { } from "@mui/x-data-grid/themeAugmentation";
import { TrackMatcher } from "@ds-services/spotify/helpers";
import type { PlaylistStatus } from "@ds-components/Main/heroselect";
import { HeroLookup } from "@ds-types/dotes";
import { SpotifySearchResults, SpotifyPlaylist } from "@ds-types/spotify";
import SpotifyActions from "@ds-components/Playlist/spotifyactions";
import SpotifyModal from "@ds-components/Playlist/dotaspottomodal";
import { stat } from "fs";


/** === ☠ ☠ ☠ ===>

Setting the Grid up

<=== ☠ ☠ ☠ === **/
export interface DotaHeroSpotifyAuth {
	thehero: {
		status: string;
		payload: HeroLookup;
	};
	spotifyauth: {
		status: string;
	};
	checkPlaylistVisible: (show?: PlaylistStatus) => PlaylistStatus;
	seePlaylistStatus: PlaylistStatus;
}

export interface SpotifyTingState {
	tracks: SpotifySearchResults["tracks"]["items"][] | string;
	playing?: boolean;
	tracksloaded?: boolean;
	tracksselected?: boolean | GridRowSelectionModel;
	searchterms: string[] | [];
	playlist: SpotifyPlaylist | boolean;
	showPlaylist: PlaylistStatus;
	playlistChecked: boolean;
};

const columns: GridColDef[] = [
	{
		field: "album.images[0].url",
		headerName: "Album Art",
		sortable: false,
		width: 100,
		renderCell: (params: GridRenderCellParams<any>) => (
			<img
				src={params?.row?.album?.images[0]?.url}
				style={{
					width: "100%",
					height: "auto",
				}}
			/>
		),
	},
	{
		field: "artist.name",
		headerName: "Artist",
		flex: 0.15,
		// minWidth: 400,
		width: 100,
		valueGetter: (value, row: SpotifySearchResults["tracks"]["items"]) => {
			return `${row?.artists[0]?.name}`
		}
		// rowSpanValueGetter: (params: any) => ,
	},
	{
		field: "album.name",
		headerName: "Album Name",
		flex: 0.15,
		// minWidth: 400,
		width: 100,
		valueGetter: (value, row: SpotifySearchResults["tracks"]["items"]) => {
			return `${row?.album?.name}`
		},
	},
	{
		field: "name",
		headerName: "Track Name",
		flex: 0.6,
		minWidth: 400,
		// width: 500,
	},
	{
		field: "preview_url",
		headerName: "Track Preview",
		sortable: false,
		// width: "100%",
		flex: 0.25,
		renderCell: (params: GridRenderCellParams<any>) => (
			!params.row.preview_url && (
				<div>Preview unavailable</div>
			) ||
			params.row.preview_url && (
				<audio controls src={params.row.preview_url}>
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			)

		),
	},
];

const playlistTheme = createTheme({
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					color: "#FFF",
					backgroundColor: DotaSpottoTheme.primary,
					fontFamily: DotaSpottoTheme.font,
				},
				columnHeaderTitle: {
					fontFamily: DotaSpottoTheme.font2,
					fontWeight: 800,
					color: "#000"
				}
			},
		},
	},
});
class SpotifyRateLimiter {
	maxRequests: number;
	requests: any[];
	windowMs: number;
	constructor(maxRequests = 30) {
		this.maxRequests = maxRequests;  // Maximum requests in 30-second window
		this.requests = [];              // Timestamp log of requests
		this.windowMs = 30 * 1000;       // 30 seconds in milliseconds
	}

	// Instead of filtering twice, combine the operations
	async throttle() {
		const now = Date.now();
		this.requests = this.requests.filter(timestamp => now - timestamp < this.windowMs);

		if (this.requests.length >= this.maxRequests) {
			const oldestRequest = this.requests[0];
			const delayMs = oldestRequest + this.windowMs - now;

			if (delayMs > 0) {
				await new Promise(resolve => setTimeout(resolve, delayMs));
				// Update timestamp after delay without another filter operation
				this.requests.shift(); // Remove oldest request
			}
		}
		this.requests.push(now);
	}

	async getSpotifyResults(term: string, auth: string): Promise<SpotifySearchResults | undefined> {
		await this.throttle();
		try {
			// Generate random integer between 10 and 50 in increments of 10
			const randomTens = Math.floor(Math.random() * 6) * 10;
			const spotifyFetchUrl = `https://api.spotify.com/v1/search?type=track&limit=10&q=${term}&offset=${randomTens}`;
			// console.log(`🥮grim__spotifyFetchUrl==> ==> ==>spotifyFetchUrl`);
			// console.log({ grim__spotifyFetchUrl: spotifyFetchUrl });
			const spotifyTracks = await fetch(spotifyFetchUrl, {
				method: "GET",
				headers: { Authorization: "Bearer " + auth },
			})
				.then(async (response) => {

					if (!response.ok) {
						// Handle rate limit response specifically
						if (response.status === 429) {
							const retryAfter = response.headers.get('Retry-After');
							if (retryAfter) {
								await new Promise(resolve =>
									setTimeout(resolve, parseInt(retryAfter) * 1000)
								);
								// Retry the request
								return this.getSpotifyResults(term, auth);
							}
						}
						throw new Error(`HTTP error! status: ${response.status}`);
					}

					return await response.json() as SpotifySearchResults;

				})
				.catch((error) => {
					// this.auth = null;
					// this.props.spotifyauth.status = "nope";
					throw error;
				});
			return spotifyTracks;
			// return SpotifyResults;
		} catch (error) {
			console.error(error);
		}
	};
}

/** === ☠ ☠ ☠ ===>

Tracks

<=== ☠ ☠ ☠ === **/
class SpotifyTing extends React.Component<DotaHeroSpotifyAuth, SpotifyTingState> {
	constructor(props: any) {
		super(props);
		this.state = {
			tracks: "loading",
			playing: false,
			tracksloaded: false,
			tracksselected: false,
			searchterms: [],
			playlist: false,
			showPlaylist: this.props.checkPlaylistVisible(),
			playlistChecked: false
		};
	}
	/** === ☠ ☠ ☠ ===>

Component Functions

<=== ☠ ☠ ☠ === **/
	batchSpotifyRequests = async (urls: string[], auth: string) => {
		const spotifyLimiter = new SpotifyRateLimiter(30); // 30 requests per 30 seconds
		const results: SpotifySearchResults["tracks"][] | null = [];

		for (const url of urls) {
			try {
				const result = await spotifyLimiter.getSpotifyResults(url, auth);
				if (result) {
					results.push(result.tracks);
				}
			} catch (error) {
				console.error(`Error fetching ${url}:`, error);
			}
		}
		return results;
	}

	spotifySearch = async (herodata: HeroLookup, auth: string) => {
		// For batch requests

		// const qTrack = `https://api.spotify.com/v1/search?type=track&limit=10&q=`;
		const searchTerms: string[] = [];

		// <=== 💸  What we looking for?  💸 ===>
		const heroName = herodata.displayName;
		if (heroName) searchTerms.push(heroName);
		const checkWords = await generateContent(herodata.bio);

		if (checkWords) {
			searchTerms.push(...checkWords as string[]);
		}
		if (searchTerms.length > 0) {
			this.setState({
				searchterms: searchTerms,
				tracks: "almost"
			});
		}
		// <=== 💸  What we looking for?  💸 ===>
		const getTracks = await this.batchSpotifyRequests(searchTerms, auth);
		return getTracks;
	};

	getTracks = () => {
		return this.state.tracks;
	};
	getTracksLoaded = () => {
		return this.state.tracksloaded;
	};
	getPlaylist = (createdplaylist: any) => {
		this.setState({
			playlist: createdplaylist
		})
	}
	updateTracks = (userdata: DotaHeroSpotifyAuth) => {
		const trackSearch = this.spotifySearch(
			userdata.thehero.payload,
			userdata.spotifyauth.status
		);

		trackSearch
			.then((herotracks) => {

				if (herotracks) {

					const layoutTracks = herotracks;

					const foundAlbum: Set<string> = new Set();

					const getSpotifyTracksArray: SpotifySearchResults["tracks"]["items"][] = layoutTracks
						.map((results) => results.items).flat(20);

					const layoutTracksFiltered = getSpotifyTracksArray
						.filter((track: SpotifySearchResults["tracks"]["items"]) => {
							if (!foundAlbum.has(track.album.id)) {
								foundAlbum.add(track.album.id);
								return true;
							}
							return false;
						});
					// const foundTrackNames: Set<string> = new Set();
					const useTrackMatcher = new TrackMatcher();
					const uniqueTracks = useTrackMatcher.filterDuplicates(layoutTracksFiltered);

					this.setState({
						tracks: uniqueTracks,
					});
				}
			})
			.catch((error) => {
				console.log(`🥮grim__error==> ==> ==>error`);
				console.log({ grim__error: error });
				this.setState({
					tracks: "error",
				});
				return <>
					Error
				</>;
			});
	};
	addAudioListeners = () => {
		const audioplayers = document.querySelectorAll("audio");
		if (audioplayers) {
			audioplayers.forEach((player) => {
				player.addEventListener("play", (event) => {
					audioplayers.forEach((audioel: HTMLAudioElement) => {
						if (audioel !== event.target) {
							audioel.pause();
						} else {
							audioel.play();
						}
					});
				});
			});
		}
	};
	componentDidMount() {
		const herostatus = this.props.thehero.status;

		if (herostatus === "loaded") {
			this.updateTracks(this.props);
		}
	}
	componentDidUpdate(prevProps: any) {
		
		if (prevProps.thehero.payload !== this.props.thehero.payload) {
			this.updateTracks(this.props);
		}
		if ((this.state.tracks === "loading" || this.state.tracks === "almost") && this.state.playlistChecked) {
			this.setState({
				playlistChecked: false
			})
			
		}
		if (this.state.tracks !== "loading" && this.state.tracks !== "almost" && this.state.tracks !== "error" && !this.state.playlistChecked) {
			const showLoadPlaylist = {
				status: true,
				visible: true,
			}
			this.setState({
				showPlaylist: showLoadPlaylist,
				playlistChecked: true
			});
			this.props.checkPlaylistVisible(showLoadPlaylist);
		}
		console.log(`🥮grim__seePlaylistStatus==> ==> ==>seePlaylistStatus`);
		console.log({grim__seePlaylistStatus: this.props.seePlaylistStatus});
		// console.log(`🥮grim__showPlaylist==> ==> ==>showPlaylist`);
		// console.log({grim__showPlaylist: this.props.showPlaylist});
		// if (prevProps.showPlaylist !== this.props.showPlaylist) { 

		// }
	}
	render() {
		const setTrackLoadedState = (e: any) => {
			if (e && this.getTracksLoaded() === false) {
				this.addAudioListeners();
				this.setState({
					tracksloaded: true,
				});
			}
		};
		const resetTrackLoadedState = (e: any) => {
			if (e && this.getTracksLoaded() === true) {
				this.setState({
					tracksloaded: false,
				});
			}
		};
		const handleHidePlaylist = () => {
			const hidePlaylist = {
				status: true,
				visible: false
			};
			this.setState({
				showPlaylist: hidePlaylist,
			});
			this.props.checkPlaylistVisible(hidePlaylist);
		};

		const getSpotyTracks = this.getTracks();
		// console.log(`🥮grim__getSpotyTracks==> ==> ==>getSpotyTracks`);
		// console.log({ grim__getSpotyTracks: getSpotyTracks });
		const DrawerHeader = styled('div')(({ theme }) => ({
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			justifyContent: 'flex-end',
		}));
		return (
			<>
				{getSpotyTracks === "loading" && (
					<Container>
						<ThemeProvider theme={DotaSpottoTheme}>
							<Backdrop
								sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
								open={true}
							>
								<div>
									Warming up
								</div>
								<CircularProgress color="inherit" />

							</Backdrop>
						</ThemeProvider>
					</Container>
				)}
				{
					getSpotyTracks === "almost" && (
						<Container>
							<ThemeProvider theme={DotaSpottoTheme}>
								<Backdrop
									sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
									open={true}
								>
									<div>
										Compiling tracks {this.state.searchterms.length > 0 && `for ${this.state.searchterms[0]}`}
									</div>
									<CircularProgress color="inherit" />
								</Backdrop>
							</ThemeProvider>
						</Container>
					)
				}
				{
					getSpotyTracks === "error" && (
						<Container>
							<ThemeProvider theme={DotaSpottoTheme}>
								<>
									ERROR!
								</>
							</ThemeProvider>
						</Container>
					)
				}
				{getSpotyTracks !== "loading" && getSpotyTracks !== "almost" && (
					<>
						<Drawer
							variant="persistent"
							anchor="right"
							sx={{
								width: '50vw',
								flexShrink: 0,
								[`& .MuiDrawer-paper`]: { width: '50vw', boxSizing: 'border-box' },
							}}
							open={this.props.seePlaylistStatus.visible}
						>
							<Container>
								<DrawerHeader onClick={handleHidePlaylist}>
									Close
								</DrawerHeader>
								<ThemeProvider theme={DotaSpottoTheme}>
									<SpotifyActions
										hero={this.props.thehero.payload.displayName ?? "No Hero"}
										alltracks={this.state.tracks}
										selectedtracks={this.state.tracksselected}
										spotifyauth={this.props.spotifyauth.status}
										getPlaylist={this.getPlaylist}
										searchterms={this.state.searchterms}
									/>
								</ThemeProvider>
							</Container>
							{this.state.playlist && (
								<>
									<Container>
										<h1>SPOTIFY PLAYLIST</h1>
										<SpotifyModal
											playlist={this.state.playlist}
											spotifyauth={this.props.spotifyauth.status}
										/>
									</Container>
								</>
							)}

							<Box sx={{ width: "100%" }}>
								<ThemeProvider theme={playlistTheme}>
									<DataGrid
										loading={getSpotyTracks === "loading"}
										rows={getSpotyTracks as any[]}
										columns={columns}
										getRowId={(row) => row.uri}
										rowHeight={100}
										// slotProps={{
										// 	row: {
										// 		onMouseEnter: setTrackLoadedState,
										// 		onMouseLeave: resetTrackLoadedState,
										// 	},
										// }}
										disableVirtualization
										checkboxSelection
										disableRowSelectionOnClick
										onRowSelectionModelChange={(selectedTracks) => {
											console.log(`🥮grim__selectedTracks==> ==> ==>selectedTracks`);
											console.log({ grim__selectedTracks: selectedTracks });
											if (selectedTracks.length > 0) {
												this.setState({
													tracksselected: selectedTracks,
												});
											} else {
												this.setState({
													tracksselected: false,
												});
											}
										}}

									/>
								</ThemeProvider>
							</Box>
						</Drawer>
					</>
				)}
			</>
		);
	}
}

export default SpotifyTing;
