import * as React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from "./serviceWorker";

import './index.scss';

import HeroSelect from "@ds-components/Main/heroselect";
import SpotifyCallback from "./pages/callback";
import DotaSpottoTheme from "@ds-components/General/dotaspottotheme";
// <=== 💸  MUI Stuff  💸 ===>
import { ThemeProvider, createTheme } from "@mui/material/styles";
const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: DotaSpottoTheme.primary,
					fontFamily: DotaSpottoTheme.font,
					fontWeight: 700,
					height: 40,
					":hover": {
						backgroundColor: "#000",
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					color: "#FFF",
					backgroundColor: DotaSpottoTheme.primary,
					fontFamily: DotaSpottoTheme.font2,
				},
				popper: {
					backgroundColor: "#FFF",
				},
				groupLabel: {
					fontWeight: 800,
					color: `white`,
					backgroundImage: `linear-gradient(69deg, ${DotaSpottoTheme.primary}, #8D3020,#AB2E17, gold)`,
				},
				groupUl: {
					fontFamily: DotaSpottoTheme.font2,
					backgroundColor: `#000`,
				},
				popupIndicator: {
					backgroundColor: "silver",
					":hover": {
						backgroundColor: "gold",
					},
				},
				paper: {
					// backgroundColor: `#FFF`,
				},
				option: {
					backgroundColor: DotaSpottoTheme.primary,
					// color: "green",
					":hover": {
						backgroundColor: DotaSpottoTheme.primary,
						// color: "#FFF",
					},
				}
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: DotaSpottoTheme.primary,
				},
				root: {
					color: `white`,
					fontFamily: DotaSpottoTheme.font,
					":hover, &.Mui-focused": {
						".MuiOutlinedInput-notchedOutline": {
							borderColor: "gold",
						},
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontFamily: DotaSpottoTheme.font2,
					color: "#FFF",
					"&.Mui-focused": {
						color: "gold",
						borderColor: "gold",
					},
				},
			},
		},
	},
	// components: {
	// 	MuiContainer: {
	// 		styleOverrides: {
	// 			root: {
	// 				maxWidth: "1440px",
	// 			},
	// 		},
	// 	},
	// },
	palette: {
		background: {
			default: "#000"
		},
		primary: {
			main: DotaSpottoTheme.primary,
		},
	},
	typography: {
		body1: {
			fontFamily: DotaSpottoTheme.font,
			color: `white`,
			backgroundColor: `black`
		},
		h1: {
			fontFamily: DotaSpottoTheme.font2,
			color: `white`,
		},
		h2: {
			fontFamily: DotaSpottoTheme.font2,
		},
		h3: {
			fontFamily: DotaSpottoTheme.font2,
		},
	},
});
// serviceWorker.unregister();


const DotaSpottoApp = () => {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HeroSelect />} />
					<Route path="/callback" element={<SpotifyCallback />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

const appContainer = document.getElementById("dotapick");
if (appContainer) {
	const appRoot = createRoot(appContainer!);
	appRoot.render(<DotaSpottoApp />);
} else {
	console.error("Failed to find the 'dotapick' element in the DOM");
}
// ReactDOM.render(<DotaSpottoApp />, document.getElementById("dotapick"));



