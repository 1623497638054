// import * as React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from "./serviceWorker";

import './index.scss';

import HeroSelect from "@ds-components/Main/heroselect";
import SpotifyCallback from "./pages/callback";
import { DotaSpottoTheme, DotaSpottoFullTheme } from "@ds-components/General/dotaspottotheme";
// <=== 💸  MUI Stuff  💸 ===>
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { border } from "@mui/system";
const theme = createTheme(DotaSpottoFullTheme);
// serviceWorker.unregister();


const DotaSpottoApp = () => {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HeroSelect />} />
					<Route path="/callback" element={<SpotifyCallback />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

const appContainer = document.getElementById("dotapick");
if (appContainer) {
	const appRoot = createRoot(appContainer!);
	appRoot.render(<DotaSpottoApp />);
} else {
	console.error("Failed to find the 'dotapick' element in the DOM");
}
// ReactDOM.render(<DotaSpottoApp />, document.getElementById("dotapick"));



