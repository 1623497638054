import * as React from "react";


import useDotaData from "@ds-services/dota/mergedotadata";
import {
	checkSpotifyAuth,
	userIsLoggedIn,
} from "@ds-services/spotify/authentication";
import { setLocalStorage, getLocalStorage } from "@ds-services/helpers";

// <=== 💸  MUI Stuff  💸 ===>
// import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { Container } from "@mui/material";
// import { ThemeProvider } from "@emotion/react";
// import { useEffect, useState } from "react";

import DOMPurify from "dompurify";
import { useCallback, useMemo } from "react";
import { HeroLookup } from "@ds-types/dotes";
import { HeroDataService } from "@ds-types/responsestate";
import { fontSize, width } from "@mui/system";

// <=== 💸  Theme Settings  💸 ===>
// const dotaRed = DotaSpottoTheme.primary;
// const themeFont = DotaSpottoTheme.font;
// const highlightFont = DotaSpottoTheme.font2;
// const dotaSelector = createTheme({
// 	components: {

// 		MuiButton: {
// 			styleOverrides: {
// 				root: {
// 					backgroundColor: DotaSpottoTheme.primary,
// 					fontFamily: DotaSpottoTheme.font,
// 					fontWeight: 700,
// 					height: 40,
// 					":hover": {
// 						backgroundColor: "#000",
// 					},
// 				},
// 			},
// 		},
// 	},
// });

interface HeroSelectorProps {
	herolist: HeroDataService<HeroLookup[]>;
	sendDataToParent: (data: any) => void;
}

const HeroSelector: React.FC<HeroSelectorProps> = ({ herolist, sendDataToParent }) => {
	// Compute and sort hero options
	const options = useMemo(() => {
		if (herolist.status !== "loaded") return [];
		if (herolist.status === "loaded" && herolist.payload) {
			console.log(`🥮grim__herolist==> ==> ==>herolist`);
			console.log({ grim__herolist: herolist });

			return herolist.payload.map((hero: HeroLookup) => {
				const firstLetter = hero.localized_name[0].toUpperCase();
				return {
					firstLetter: /[a-zA-Z]/.test(firstLetter) ? firstLetter : "0-9",
					...hero,
				};
			})
				.sort((a, b) => a.firstLetter.localeCompare(b.firstLetter));
		}
	}, [herolist]);
	// Set default hero from local storage or fallback to the first hero
	const defaultHero = useMemo(() => {
		const storedHero: HeroLookup | null = getLocalStorage("the_hero") ? JSON.parse(getLocalStorage("the_hero") as string) as HeroLookup : null;
		return storedHero || options?.[0];
	}, [options]);

	const [value, setValue] = React.useState<any | null>(defaultHero);
	const [inputValue, setInputValue] = React.useState("");

	console.log(`🥮grim__options==> ==> ==>options`);
	console.log({ grim__options: options });

	/**
	 * Other Funks
	 */
	// Handle button click for hero selection
	const handleHeroUpdate = useCallback(
		async (e: React.MouseEvent<HTMLButtonElement>) => {
			try {
				const currentTargetId = e.currentTarget.id;
				const response = await checkSpotifyAuth();
				console.log(`🥮grim__response==> ==> ==>response`);
				console.log({ grim__response: response });
				if (!response || response === "Remove access_code") {
					if (userIsLoggedIn()) {
						window.location.reload();
					} else if (e.type === "click") {
						sendDataToParent({ showReminder: true, eventType: e.type });
					}
				}
				else if (currentTargetId && currentTargetId === "hero-select-button") {
					sendDataToParent(value);
				}
			} catch (error) {
				console.error("Error during hero selection update:", error);
			}
		},
		[value, sendDataToParent]
	);

	return (
		<Container>
			<Box component="form">
				<Grid container spacing={3} mt={0}>

					{/* <Grid xs="12" md="6"> */}
					<Grid size={{ xs: 12, md: 6 }}>
						<Autocomplete
							id="dota-gas"
							value={value}
							autoComplete={true}
							isOptionEqualToValue={(option: any, value: any) =>
								option.id === value.id
							}
							onChange={(event: any, newValue: string | null) => {
								if (newValue) {
									setValue(newValue);
									setLocalStorage("the_hero", JSON.stringify(newValue));
								}
							}}
							inputValue={inputValue}
							onInputChange={(event, newInputValue) => {
								console.log(`🥮grim__newInputValue==> ==> ==>newInputValue`);
								console.log({ grim__newInputValue: newInputValue });
								if (newInputValue) {
									console.log(`😶‍🌫️grim__input==> ==> ==>changed`);
									setInputValue(newInputValue);
								}
							}}
							options={options ?? []}
							groupBy={(option: any) => option.firstLetter}
							getOptionLabel={(option: any) => option.displayName}
							// style={{ width: 420 }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Select Your Hero"
									variant="outlined"
									fullWidth
								/>
							)}
							renderOption={(props, option) => {
								return (
									<ListItem {...props} key={option.localized_name} disablePadding>
										<Avatar
											src={`https://cdn.dota2.com${option.icon}`}
											className={`heroface`}
											sx={{ mr: 2 }}
										/>
										<React.Fragment>
											<div>
												<span>{option.displayName}</span>
											</div>
										</React.Fragment>
									</ListItem>
								)
							}}
						// render
						/>
					</Grid>
					<Grid size={{ xs: 12, md: 6 }}>
						<Button
							variant="contained"
							size="large"
							id="hero-select-button"
							onClick={handleHeroUpdate}
						>
							Hero Selection
						</Button>
					</Grid>
				</Grid>
			</Box>
			<br />
			<div>
				<>
					{value && value.bio ? (
						<Box
							sx={{}}>
							<Box sx={{
								"@keyframes backgroundSlide": {
									"100%": {
										backgroundPositionX: "256px"
										// backgroundPositionX: "100%"
										// transform: "translateX(100%)"
									}
								},
								backgroundImage: `url(https://cdn.dota2.com${value.img})`,
								backgroundRepeat: "repeat",
								backgroundBlendMode: "overlay",
								animation: "backgroundSlide 5s linear infinite",
								// backgroundSize: "cover",
								height: 144,
								width: "100%",
								fontSize: "3rem",
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}>
								<h2>
									{value.displayName}
								</h2>
							</Box>
							{/* <img
								style={{
									width: 256,
									height: 144,
								}}
								alt={`${value.displayName} Portrait`}
								src={`https://cdn.dota2.com${value.img}`}
							/> */}
							<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.bio.replace(/\\/g, '')) }}></p>
						</Box>
					) : (
						<Skeleton
							sx={{ bgcolor: "#FFF" }}
							variant="rectangular"
							animation="wave"
							width={256}
							height={144}
						/>
					)}
				</>
			</div>
		</Container>
	);
};

// const Heroes: React.FC<{ sendDataToGrandParent: () => void }> = ({
const Heroes: React.FC<{ sendDataToGrandParent: (index: number) => void }> = ({
	sendDataToGrandParent
}) => {
	const dotaData = useDotaData();

	// if (!heroData) return <div>No data available</div>
	if (dotaData.status === "error") {
		console.error(`🥮grim__dotaselectorError==> ==> ==>dotaselectorError`);
		console.error({ grim__dotaselectorError: dotaData.error });
	}
	return (
		<Box
			sx={{
				pt: { xs: 2, sm: 5 },
				flexGrow: 1
			}}
		>
			{dotaData.status === "loading" || dotaData.status === "init" && <Container>Loading...</Container>}
			{dotaData.status === "error" && <div>Error: Error Dawg. Check console logs.</div>}
			{dotaData.status === "loaded" &&
				<HeroSelector
					herolist={dotaData}
					sendDataToParent={sendDataToGrandParent}
				/>}
			<br />
		</Box>
	);
};
export default Heroes;
