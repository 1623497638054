import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "../services/helpers"; 

const SpotifyCallback: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// Extract the access token from URL (assuming it's part of the URL fragment)
		const hash = window.location.hash;
		const params = new URLSearchParams(hash.replace("#", ""));

		const accessToken = params.get("access_token");
		if (accessToken) {
			// Store the token
			setLocalStorage("access_code", accessToken);

			// Update the Spotify auth state
			// setSpotifyAuth({
			// 	status: accessToken,
			// 	type: "token",
			// });

			// Redirect back to main page or wherever appropriate
			navigate("/");
		} else {
			// Handle error or failed authentication
			console.error("No access token found in the callback URL.");
		}
	}, [navigate]);

	return (
		<div>
			<p>Authenticating...</p>
		</div>
	);
};

export default SpotifyCallback;
