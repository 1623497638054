import * as React from "react";

import { DotaSpottoTheme, DotaSpottoFullTheme } from "@ds-components/General/dotaspottotheme";

import { checkSpotifyAuth } from "@ds-services/spotify/authentication";

// <=== 💸  MUI Stuff  💸 ===>
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Slide from "@mui/material/Slide";
import { Button, Typography } from "@mui/material";

const token = process.env.REACT_APP_SPOTY_CLIENT_ID;
const redirectUrl = process.env.REACT_APP_REDIRECT_URI;
console.log(`🎠grim__REDIRECT_URI==>==>==>${redirectUrl}`);
const spotAuthEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
const RESPONSE_TYPE = "token";
// <=== 💸  Theme Settings  💸 ===>
const theme = createTheme(DotaSpottoFullTheme);
const primaryFont = DotaSpottoTheme.primary;
interface LoggedIn {
	active: boolean;
	hero?: any;
	event?: string | null;
}

const loginAlertActive = () => {
	const loginAlertIcon = document.getElementById("login-reminder");
	if (loginAlertIcon) {
		return true;
	} else {
		return false;
	}
};

const SpotifyLogin: React.FC<{
	spotifyauthentication: any;
	reminder: any;
}> = ({ spotifyauthentication, reminder }) => {
	// <=== 💸  START States  💸 ===>
	const [authorization, setSpotifyAuthorization] = React.useState<any | null>({
		status: "checking",
	});

	const [loginalert, setLoginAlert] = React.useState<LoggedIn>({
		active: false,
	});
	const spotifyLoginButton = document.getElementById("spotify-login-link");
	const loginHover = (e: any) => {
		const loginAlertCheck = loginAlertActive();

		if (
			e.target.id === "spotify-login-link" &&
			loginalert.event === "click" &&
			loginAlertCheck
		) {
			setLoginAlert({
				active: false,
				event: e.type,
			});
		}
	};

	if (reminder && spotifyLoginButton) {
		const loginAlertCheckClick = loginAlertActive();

		if (loginalert.active === false && !loginAlertCheckClick) {

			setLoginAlert({
				active: true,
				event: reminder.eventType,
			});
		}
	}

	// <=== 💸  END States  💸 ===>

	if (authorization.status === "checking") {
		const SpotifyAuthorization = checkSpotifyAuth();
		SpotifyAuthorization.then((response: any) => {
			// console.log(response);
			if (response) {
				setSpotifyAuthorization({
					status: response,
				});
				spotifyauthentication(response);
			} else {
				setSpotifyAuthorization({
					status: "failed",
				});
			}
		}).catch((error) => {
			console.log(error);
		});
	}
	return (
		<AppBar
			position="static"
			enableColorOnDark
			sx={{
				boxShadow: 0,
				bgcolor: 'transparent',
				backgroundImage: 'none',
				// mt: 'calc(var(--template-frame-height, 0px) + 28px)',
			}}
		>
			<Container sx={{
				padding: 1
			}}>
				<ThemeProvider theme={theme}>
					{(!authorization.status ||
						authorization.status === "Remove access_code" ||
						authorization.status === "authentication needed" ||
						authorization.status === "failed") && (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									gap: 2,
								}}
							>
								<Typography sx={{ fontSize: "1.25rem", bgcolor: "transparent" }} component="div" className="static-text">
									Start Building Your Playlist:
								</Typography>
								<Button
									id="spotify-login-link"
									variant="contained"
									href={`${spotAuthEndpoint}?client_id=${token}&redirect_uri=${redirectUrl}&scope=playlist-modify-public&response_type=${RESPONSE_TYPE}`}
									onMouseEnter={loginHover}
								>
									Log Into Spotify
								</Button>
								<Slide
									direction="left"
									in={loginalert.active}
									mountOnEnter
									unmountOnExit
									style={{
										display: "inline-flex",
									}}
								>
									<Box>
										<img
											src="/mirana_ohlook.png"
											height={40}
											width={40}
											id="login-reminder"
										/>
									</Box>
								</Slide>
							</Box>
						)}
					{authorization.status.type === "user" && (
						<>
							<Box id="spotify-user-profile">
								<Stack direction="row" spacing={1} sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									gap: 2,
								}}>
									<Chip
										avatar={
											<Avatar
												// sizes="small"
												alt={`${authorization.status.display_name} Portrait`}
												src={authorization.status.images[0].url}
											/>
										}
										component="a"
										target="_blank"
										color="primary"
										// sx={{ height: 70 }}
										// size="medium"
										href={authorization.status.external_urls.spotify}
										label={authorization.status.display_name}
										sx={{
											// height: 70,
											"& .MuiChip-label": {
												fontSize: "1.25rem",
												paddingRight: 2,
											},
										}}
										clickable
									/>
								</Stack>
							</Box>
						</>
					)}
				</ThemeProvider>
			</Container>
		</AppBar>
	);
};

export default SpotifyLogin;
