import * as React from "react";

import Heroes from "@ds-components/General/dotaselector";
import { Divider, Box, Modal, Button, Fade, Container } from "@mui/material";
import SpotifyTing from "@ds-components/Playlist/playlist";
import SpotifyLogin from "@ds-components/Main/spotifylogin";
import Credits from "@ds-components/Main/credits";
import { getSpotifyDetail } from "@ds-services/helpers";

export interface PlaylistStatus {
	status?: boolean,
	visible?: boolean

}

const HeroSelect: React.FC<{}> = () => {

	

	const [spotifyauth, setSpotifyAuth] = React.useState<any | null>({
		status: "checking",
		type: null,
	});

	const spotifyauthentication = (index: any) => {
		if (index.type === "user") {
			const accountToken = getSpotifyDetail.getAuthToken();
			setSpotifyAuth({
				status: accountToken,
				type: "token",
			});
		}
	};

	const [thehero, setTheHero] = React.useState<any | null>({
		status: "loading",
	});

	const sendDataToGrandParent = (index: any) => {
		setTheHero({
			status: "loaded",
			payload: index,
		});
	};

	const [playlistVisible, setPlaylistVisible] = React.useState<PlaylistStatus>({
		status: false,
		visible: false
	});

	const checkPlaylistVisible = (show?: PlaylistStatus) => {
		console.log(`🥮grim__show==> ==> ==>show`);
		console.log({grim__show: show});
		if (show) {
			setPlaylistVisible(show);
		} else {
			setPlaylistVisible({
				status: false,
				visible: false
			});
		}
		return playlistVisible;
	};

	console.log(`🥮grim__thehero==> ==> ==>thehero`);
	console.log({ grim__thehero: thehero });
	console.log(`🥮grim__playlistStatus==> ==> ==>playlistStatus`);
	console.log({ grim__playlistStatus: playlistVisible });
	return (
		<>
			<SpotifyLogin
				spotifyauthentication={spotifyauthentication}
				reminder={thehero.payload}
			/>
			<Box sx={{ display: 'flex' }}>
				<Box>
					<Heroes sendDataToGrandParent={sendDataToGrandParent} />
					{
						!playlistVisible.visible && playlistVisible.status && (
							<Container>
								<Button
									variant="contained"
									size="large"
									onClick={() => {
										console.log("check that");
										setPlaylistVisible({
											visible: true,
										});
									}}>Show Playlist</Button>
							</Container>
						)
					}
				</Box>
				{spotifyauth.type === "token" && thehero.status === "loaded" && (
					<SpotifyTing thehero={thehero} spotifyauth={spotifyauth} checkPlaylistVisible={checkPlaylistVisible} seePlaylistStatus={playlistVisible} />
				)}
			</Box>
			<Divider></Divider>
			<Credits />
		</>
	);
};
export default HeroSelect;
