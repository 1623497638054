import * as React from "react";
import { Container, Button, Modal, Fade, Box } from "@mui/material";


const Credits: React.FC<{}> = () => {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const modalStyle = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      color: "#000",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
   };
   return (
      <Container>
         <Button
            onClick={handleOpen}
            variant="contained"
            size="large"
         >Credits</Button>
         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
         // BackdropComponent={Backdrop}
         // BackdropProps={{
         // 	timeout: 500,
         // }}
         >
            <Fade in={open}>
               <Box sx={modalStyle}>
                  <>That's what's up</>
               </Box>
            </Fade>
         </Modal>
      </Container>
   );
};

export default Credits;