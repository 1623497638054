import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import zIndex from "@mui/material/styles/zIndex";
import { getOpacity } from "@mui/material/styles/createColorScheme";

const chipHeight = 60;
const DotaSpottoTheme = {
   font: `'Rokkitt', 'sans-serif'`,
   font2: `'Cardo', 'serif'`,
   primary: "#A72714"

}

const DotaSpottoFullTheme = {
   components: {
      MuiButton: {
         styleOverrides: {
            contained: {
               backgroundColor: DotaSpottoTheme.primary,
               color: "#FFF",
               fontFamily: DotaSpottoTheme.font,
               fontWeight: 700,
               height: 40,
               border: `1px solid ${DotaSpottoTheme.primary}`,
               ":hover": {
                  backgroundColor: "#000",
                  border: "1px solid gold",
               },
            },
            text: {
               fontFamily: DotaSpottoTheme.font,
               ":hover": {
                  color: "white"
               }

            }
         },
      },
      MuiAutocomplete: {
         styleOverrides: {
            root: {
               color: "#FFF",
               backgroundColor: "transparent",
               // backgroundColor: DotaSpottoTheme.primary,
               fontFamily: DotaSpottoTheme.font2,
            },
            popper: {
               backgroundColor: "#FFF",
            },
            groupLabel: {
               fontWeight: 800,
               color: `white`,
               backgroundImage: `linear-gradient(69deg, ${DotaSpottoTheme.primary}, #8D3020,#AB2E17, gold)`,
            },
            groupUl: {
               fontFamily: DotaSpottoTheme.font2,
               backgroundColor: `#000`,
            },
            popupIndicator: {
               backgroundColor: "silver",
               ":hover": {
                  backgroundColor: "gold",
               },
            },
            paper: {
            },
            option: {
               backgroundColor: DotaSpottoTheme.primary,
               ":hover": {
                  backgroundColor: DotaSpottoTheme.primary,
                  // color: "#FFF",
               },
            },
         },
      },
      MuiOutlinedInput: {
         styleOverrides: {
            notchedOutline: {
               borderColor: DotaSpottoTheme.primary,
            },
            root: {
               color: `white`,
               backgroundColor: `transparent`,
               fontFamily: DotaSpottoTheme.font,
               ":hover, &.Mui-focused": {
                  ".MuiOutlinedInput-notchedOutline": {
                     borderColor: "gold",
                  },
               },
            },
         },
      },
      MuiFormLabel: {
         styleOverrides: {
            root: {
               fontFamily: DotaSpottoTheme.font2,
               color: "#FFF",
               "&.Mui-focused": {
                  color: "gold",
                  borderColor: "gold",
               },
            },
         },
      },
      MuiChip: {
         styleOverrides: {
            root: {
               ":hover": {
                  backgroundColor: DotaSpottoTheme.primary,
               },
            },
            avatar: {
               height: chipHeight,
               width: chipHeight,
               marginLeft: 0,
            },
            label: {
               fontSize: "1.25em",
               marginLeft: 10,
               marginRight: 10,
               fontFamily: DotaSpottoTheme.font2,
            },
            clickableColorSecondary: {
               backgroundColor: "green",
            },
            filledPrimary: {
               backgroundColor: "#000",
               backgroundImage: "linear-gradient(75deg, ${dotaRed}, gold)",
               borderRadius: chipHeight,
               height: chipHeight,
            },
         },
      },
   },
   // components: {
   // 	MuiContainer: {
   // 		styleOverrides: {
   // 			root: {
   // 				maxWidth: "1440px",
   // 			},
   // 		},
   // 	},
   // },
   palette: {
      background: {
         default: "#000"
      },
      primary: {
         main: DotaSpottoTheme.primary,
      },
   },
   typography: {
      body1: {
         fontFamily: DotaSpottoTheme.font,
         color: `white`,
         backgroundColor: `black`
      },
      h1: {
         fontFamily: DotaSpottoTheme.font2,
         color: `white`,
      },
      h2: {
         fontFamily: DotaSpottoTheme.font2,
      },
      h3: {
         fontFamily: DotaSpottoTheme.font2,
      },
   },
}

const GoldButton = styled(Button)({

   backgroundColor: "gold",
   color: "#000",
   fontFamily: DotaSpottoTheme.font,
   fontWeight: 700,
   height: 40,
   border: `1px solid gold`,
   ":hover": {
      backgroundColor: "#000",
      border: "1px solid gold",
      color: "gold",
   },
});

const heroSelectorStyles = {
   display: "flex",
   flexDirection: "column",
   minHeight: "calc(100vh)",
   backgroundColor: "black",
   zIndex: 1,
}


export { DotaSpottoTheme, DotaSpottoFullTheme, GoldButton, heroSelectorStyles };