import * as React from "react";
import { Container, Button, Modal, Fade, Box } from "@mui/material";


const Credits: React.FC<{}> = () => {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const modalStyle = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      color: "#000",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
   };
   return (
      <Box sx={{
         backgroundColor: "gold",
         marginTop: "auto"
      }}>
         <Container sx={{
            textAlign: "center"
         }}>
            <Button
               onClick={handleOpen}
               className="link"
               variant="text"
               size="large"
            >Credits</Button>
            <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleClose}
               closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            // 	timeout: 500,
            // }}
            >
               <Fade in={open}>
                  <Box sx={modalStyle}>
                     <div>
                        <Button onClick={handleClose}>X</Button>
                     </div>
                     <ul>
                        <li>Adobe Colors</li>
                        <li>Open Dota</li>
                        <li>Stratz</li>
                        <li>Mui React</li>
                        <li>Spotify</li>
                        <li>Cloudflare</li>
                        
                     </ul>
                  </Box>
               </Fade>
            </Modal>
         </Container>
      </Box>
   );
};

export default Credits;