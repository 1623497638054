import { useState, useEffect } from "react";
import { HeroDataService } from "../../types/responsestate";
import { HeroLookup } from "../../types/dotes";
// import type { OpenDotaData, StratzData } from "../../types/dotes";

// import mergedata from "../../testdata/test-merged.json";

const useDotaData = () => {
	const [result, setResult] = useState<HeroDataService<HeroLookup[]>>({
		status: "init",
	});
	useEffect(() => {
		// Merge the data here
		const fetchDotaData = async () => {
			try {
				// Merge the data
				// const getData = mergeDotaData(openDotaData, stratzData);
				const dottoBlob = process.env.REACT_APP_DOTTO_FUNC ?? `/api/dotto`;
				console.log(`🥮grim__dottoBlob==> ==> ==>dottoBlob`);
				console.log({grim__dottoBlob: dottoBlob});
				// const dottoBlob = `/dotto`;
				// const dottoBlob = process.env.REACT_APP_NETLIFY_DOTTO;
				if (!dottoBlob) return;
				const retriveDotaData = await fetch(dottoBlob);
				if (!retriveDotaData.ok) {
					throw new Error(`HTTP error! Status: ${retriveDotaData.status}`);
				}
				console.log(`🥮grim__retriveDotaData==> ==> ==>retriveDotaData`);
				console.log({grim__retriveDotaData: retriveDotaData});
				const heroData: HeroLookup[] = await retriveDotaData.json();

				setResult({
					status: 'loaded',
					payload: heroData
				});
			} catch (error) {
				setResult({
					status: 'error',
					error: error as Error
				});
			}
		};
		fetchDotaData();
	}, [])

	return result;
};

export default useDotaData;