import * as React from "react";
// import DotaSpottoTheme from "./dotaspottotheme";
import { GoldButton } from "@ds-components/General/dotaspottotheme";
import { SpotifyTingState, DotaHeroSpotifyAuth } from "@ds-components/Playlist/playlist";
import { Box, Button } from "@mui/material";
import { getLocalStorage } from "@ds-services/helpers";
// import { getLocalStorage } from "../services/helpers";
import { Playlist } from "@spotify/web-api-ts-sdk";
interface SpotifyActionsProps {
   hero: string;
   spotifyauth: DotaHeroSpotifyAuth["spotifyauth"]["status"];
   selectedtracks: SpotifyTingState["tracksselected"];
   alltracks: SpotifyTingState["tracks"];
   getPlaylist: any;
   searchterms: string[];
}

const SpotifyActions: React.FC<SpotifyActionsProps> = ({ hero, spotifyauth, selectedtracks, alltracks, getPlaylist, searchterms }) => {
   console.log(`🥮grim__checkparams==> ==> ==>checkparams`);
   console.log({ grim__checkparams: hero, spotifyauth, selectedtracks, alltracks, searchterms });
   const createPlaylist = async (type: "all" | "selected") => {
      console.log(`🎠grim__type==>==>==>${type}`);
      if (spotifyauth) {
         console.log(`😶‍🌫️grim__checkpoint==> ==> ==>GOAAAL`);
         try {
            const accessToken = spotifyauth;
            const dotaHero = hero;
            const playlistName = `${dotaHero} Playlist`;
            const userId = getLocalStorage("profile_id");

            const createPlaylistResponse = await fetch(
               `https://api.spotify.com/v1/users/${userId}/playlists`, {
               method: 'POST',
               headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify({ name: playlistName, description: `Playlist created by Dotatunes. ✨ Tracks curated using these terms: ${searchterms.join(", ")}.` })
            }
            );

            const createDotaPlaylist: Playlist = await createPlaylistResponse.json();
            const playlistId = createDotaPlaylist.id;

            const allTrackUris = typeof alltracks !== "string" ? alltracks.map((track) => track.uri) : alltracks;
            const tracksToAdd = type === "all" ? allTrackUris : selectedtracks;

            // Split tracksToAdd into chunks of 100 or less
            const trackChunks = Array.isArray(tracksToAdd) ? Array.from({ length: Math.ceil(tracksToAdd.length / 100) }, (_, i) =>
               tracksToAdd.slice(i * 100, (i + 1) * 100)
            ) : false;
            if (!trackChunks) return;
            for (const chunk of trackChunks) {
               await fetch(
                  `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
                  {
                     method: 'POST',
                     headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                     },
                     body: JSON.stringify({ uris: chunk })
                  }
               );
            }
            console.log("Playlist created and tracks added successfully!");
            getPlaylist(createDotaPlaylist);
            return createDotaPlaylist;
         } catch (error) {
            console.error("Error creating playlist or adding tracks:", error);
         }
      }
   };

   return (
      <Box sx={{
         display: "flex",
         alignItems: "center",
         gap: "1rem",
      }}>
         <GoldButton
            onClick={async () => await createPlaylist("all")}
            variant="contained"
            size="large"
         >Add All Tracks</GoldButton>
         {selectedtracks && (
            <GoldButton
               onClick={async () => await createPlaylist("selected")}
               variant="contained"
               size="large"
            >Add Selected Tracks</GoldButton>
         )}
      </Box>
   );
};

export default SpotifyActions;


// const SpotifyActions: React.FC<SpotifyActionsProps> = ({ spotifyauth, selectedtracks, alltracks }) => {
//    console.log(`🥮grim__spotifyauth==> ==> ==>spotifyauth`);
//    console.log({ grim__spotifyauth: spotifyauth, selectedtracks: selectedtracks, alltracks: alltracks });
//    return (
//       <>
//          <Button>Add All Tracks</Button>
//          {selectedtracks && <Button>Add Selected Tracks</Button>}
//       </>
//    );
// }

// export default SpotifyActions;